.loader{width:6%; margin:auto;position: absolute;
    left: 50%;
    top: 35%;    
    z-index: 1000; }
.authlogo-img {height: 70px!important;margin-right: 11px;}
.full-page:after {content: "" !important;background-color: rgb(74 74 74 / 0%) !important;}
.sidebar[data-color="blue"]:after,
.off-canvas-sidebar[data-color="blue"]:after {
  background: linear-gradient(#c8ebfe 0%, #56bbf1 80%)  !important; }
  .sidebar[data-color="blue"] .nav li a,
  .sidebar[data-color="blue"] .nav li a i,
  .sidebar[data-color="blue"] .nav li a[data-toggle="collapse"],
  .sidebar[data-color="blue"] .nav li a[data-toggle="collapse"] i,
  .sidebar[data-color="blue"] .nav li a[data-toggle="collapse"] ~ div > ul > li .sidebar-mini-icon,
  .sidebar[data-color="blue"] .nav li a[data-toggle="collapse"] ~ div > ul > li > a,
  .off-canvas-sidebar[data-color="blue"] .nav li a,
  .off-canvas-sidebar[data-color="blue"] .nav li a i,
  .off-canvas-sidebar[data-color="blue"] .nav li a[data-toggle="collapse"],
  .off-canvas-sidebar[data-color="blue"] .nav li a[data-toggle="collapse"] i,
  .off-canvas-sidebar[data-color="blue"] .nav li a[data-toggle="collapse"] ~ div > ul > li .sidebar-mini-icon,
  .off-canvas-sidebar[data-color="blue"] .nav li a[data-toggle="collapse"] ~ div > ul > li > a {
    color: #FFFFFF;
    opacity: .7; }
  .sidebar[data-color="blue"] .nav li:hover:not(.active) > a,
  .sidebar[data-color="blue"] .nav li:focus:not(.active) > a,
  .off-canvas-sidebar[data-color="blue"] .nav li:hover:not(.active) > a,
  .off-canvas-sidebar[data-color="blue"] .nav li:focus:not(.active) > a {
    opacity: 1; }
  .sidebar[data-color="blue"] .logo .simple-text,
  .off-canvas-sidebar[data-color="blue"] .logo .simple-text {
    color: #54b9ef; }
  .sidebar[data-color="blue"] .logo:after,
  .off-canvas-sidebar[data-color="blue"] .logo:after {
    background-color: #54b9ef;
    opacity: .4; }
  .sidebar[data-color="blue"] .user .info a span,
  .sidebar[data-color="blue"] .user .nav .sidebar-mini-icon,
  .sidebar[data-color="blue"] .user .nav .sidebar-normal,
  .off-canvas-sidebar[data-color="blue"] .user .info a span,
  .off-canvas-sidebar[data-color="blue"] .user .nav .sidebar-mini-icon,
  .off-canvas-sidebar[data-color="blue"] .user .nav .sidebar-normal {
    color: #FFFFFF !important; }
  .sidebar[data-color="blue"] .user:after,
  .off-canvas-sidebar[data-color="blue"] .user:after {
    background-color: #54b9ef;
    opacity: .4; }
/*--------------------------------
Login Page
----------------------------------*/

.card {background-color: #ffffffde !important;}
.card-login.card {
    border-radius: inherit !important;
}

.card-login .btn-round {
    border-radius: 5px !important;
    background-color: #85cff5 !important;
}

.card-login .text-info {
    color: #000 !important;
}
.card-login .card-header, .card-login .card-body, .card-login .card-footer {
    padding-left: 30px!important;
    padding-right: 30px !important;
}

input.form-control, .input-group-append .input-group-text, .input-group-prepend .input-group-text {
    border-color: black !important;
}
/*--------------------------------
Header-section
----------------------------------*/
a#navbarDropdownMenuLink img {
    border-radius: 50pc;
}



/*--------------------------------
Sidemenu
----------------------------------*/
.sidebar li.active a, .sidebar li.active a i {
    color: #13f7f7 !important;
}

.sidebar li.active {
    background-image: linear-gradient(to right, #008ace , #63c0f2);
}

.sidebar li a, .sidebar li a i {
    opacity: 100 !important;
    text-transform: capitalize !important;
}

.sidebar ul li a p {
    font-size: 16px !important;
    font-weight: 400 !important;
}

.logo:after {
    display: none !important;
}
/*--------------------------------
Dashboard
----------------------------------*/

.main-panel {
    background-image: linear-gradient(to bottom right, #ffffff, #f1f5fc);
}
.navbar.navbar-transparent {
    border: inherit !important;
}

button#minimizeSidebar {
    background: #c0e7fd;
}
.btn-info {
    background-color: #8ed9ff !important;
}
.sidebar .sidebar-wrapper li.active>a:not([data-toggle=collapse]):after {
    border-right: 17px solid #f9fbfe !important;}
.text-info {
    color: black !important;
}

.card-header {
    padding-right: 30px !important;
    padding-left: 30px !important;
}
.card-body {
    padding: 30px !important;
}

form.topic_search_form {
    margin-bottom: 30px;
}


.topic_search_form .btn-info {
    margin-top: 0px !important;
}
form.topic_search_form input {
    border-color: #DFDFDF !important;
    padding: 9px 15px;
}

.table-responsive {
    overflow: auto;
}


.table-striped tbody tr:nth-of-type(odd) {
    background: none;
}

.topic_term_radio {
  display: flex;
  border: 1px solid #E3E3E3;
  padding: 7px 9px 6px 6px;
  border-radius: 5px;
}
.topic_term_radio div {
  margin: 0px;
  margin-right: 16px;
}
.qrcde_inner {
  float: right;
}
#RegisterValidation input {
  border-color: #E3E3E3 !important;
}
button#minimizeSidebar:hover {
  background: #008ACE !important;
}
.custom-page-link{    background-color: #8ED9FF!important;font-size: .8571em;border-radius: 3px;padding: 8px 15px;color:white!important; margin: 0px 10px;}
.page-item.active .custom-page-link {
    background-color: white !important;
    color: #8ed9ff !important;
}
.qrcodeid{text-align: right;padding-right: 28px;}
.add_topic_form .card-title {
    margin-top: 10px;
    float: left;
    display: block;
    width: 50%;
}

@media screen and (max-width: 600px) { 
    .topic_term_radio {display:block;}
}